<template>
  <div>
    <NavBar class="fixed top-0 left-0 right-0"></NavBar>

    <secondary-navbar
      title="Thank you for your payment"
      :showbroker="false"
      :showaction="false"
      :showportfolio="true"
    />

    <div class="w-full mt-6 grid grid-cols-10 hidden sm:grid" id="page-login">
      <div class="col-span-2 sm:block 0 background-f4">
        <left-side-strip :showBrokerButton="true" :showPie="false" />
      </div>

      <div class="col-span-8 m-20">
        <div class="py-5" style="">
          <h1 class="text-4xl oeno_portfolio_title pb-10">
            Order Summary
          </h1>

          <table class="w-full mt-10">
            <thead class="border-b">
              <tr>
                <th scope="col" class="oeno_portfolio_tabletitle px-3 py-3">
                  Wine
                </th>
                <th scope="col" class="oeno_portfolio_tabletitle px-3 py-3">
                  Quantity
                </th>
                <th scope="col" class="oeno_portfolio_tabletitle px-3 py-3">
                  Cost
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="orderitem in orderData.items"
                v-bind:key="orderitem.id"
                class=""
              >
                <td class="px-3 py-3 uppercase content-center">
                  {{ orderitem.name }}
                </td>
                <td class="px-3 py-3 items-center">{{ orderitem.quantity }}</td>
                <td class="px-3 py-3 content-center">
                  {{ orderData.currency }}
                  {{ currencyFormatter(orderitem.price) }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="flex justify-center">
          <button
            type="submit"
            @click="completeForm()"
            class="w-72 h-10 oeno_button_black mt-10 align-center"
            >{{ updateStatus ? "Processing...." : "Continue " }}</button
          >
        </div>
      </div>
    </div>

    <Footer></Footer>
  </div>
</template>

<script>

import { HTTP } from "@/axios.js";
import NavBar from "@/components/layout/navbar.vue";
import SecondaryNavbar from "@/components/layout/SecondaryNavbar.vue";
import Footer from "@/components/layout/footer.vue";
import LeftSideStrip from "@/components/pageparts/LeftSideStrip.vue";

export default {
  components: {
    NavBar,
    Footer,
    LeftSideStrip,
    SecondaryNavbar,
  },
  data() {
    return {
      deliveryAddress: null,
      updateStatus: false,
      orderData: null,
    };
  },
  created: function () {
    this.$store.dispatch("storeOrderData", {});

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + this.$store.state.token,
    };

    HTTP.get("/api/get-order/" + this.$route.query.ref, { headers: headers })
      .then((response) => {
        this.orderData = response.data.data.order;
      })
      .catch((response) => {
        if (response.response.status == 401) {
          this.$store.dispatch("login", { token: "" });
          this.$router.push("/login");
          this.$store.dispatch("storePortfolioData", {});
        }
      });
  },
  methods: {
    completeForm() {
      this.$router.push({
        name: "page-portfolio",
      });
    },
  },
};
</script>
